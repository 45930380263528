import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import ResponsiveImg from '../../components/ResponsiveImg';
import { openFindNeosurf, commonHeaderColor } from '../../util';


const PaysafeCardPage = ({ data }) => (
  <>
    <SEO
      title="Neosurf voucher replaces Paysafe Card - Secure Deposits"
      description="Buy Neosurf voucher - a new Paysafe Card replacement. Safe &amp; secure deposits to gambling &amp; e2ntertainment websites."
      alternateLanguagePages={[
        {
          path: 'paysafecard',
          language: 'en'
        },
        {
          path: 'paysafecard',
          language: 'fr'
        },
        {
          path: 'paysafecard',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.betbtc.io/sign-up/a48acf12" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.paysafeCard.childImageSharp.fluid} alt="Buy Neosurf &amp; pay securely online" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">NeoSurf ersetzt jetzt die Paysafe Card und funktioniert perfekt in Australien und weltweit!</h1>
            <p className="textColorDark">Hattest du in letzter Zeit Probleme beim Einzahlen mit der Paysafe Card? Bist du aus Australien und Paysafe-Transaktionen sind aufgrund neuer Gesetze und Vorschriften nicht mehr möglich?</p>
            <p className="textColorAccent bigger zeroMarginT"><em>Zum Glück gibt es eine Lösung!</em></p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller">NEOSURF HIGHLIGHTS</h2>
            <ul>
              <li className="textColorDark">Genau das gleiche Gutscheinsystem wie die Paysafe Card</li>
              <li className="textColorDark">Sicher & geschützt für Internet-Transaktionen</li>
              <li className="textColorDark">Kaufe Neosurf an über 135.000 Standorten weltweit</li>
            </ul>
          </div>
        </div>
        <div>
          <Img fluid={data.neosurf.childImageSharp.fluid} alt="Neosurf-Codes sind in 135.000 Geschäften weltweit erhältlich" />
        </div>
      </div>
    </ContentRow>
    <ContentRow
      backgroundColor="rgba(255,0,155, 0.8)"
      Img={
        <ResponsiveImg
          aspectRatio={data.findNeosurf.childImageSharp.fixed.aspectRatio}
          src={data.findNeosurf.childImageSharp.fixed.src}
          alt=""
        />
      }
      padV
    >
      <div className="contentBox textAlignCenter">
        <h2 className="smaller textColorComplementary">Finde einen Neosurf-Händler in deiner Nähe</h2>
        <p className="textColorLight">Kunden bevorzugen Neosurf-Bargeldgutscheine, da sie an tausenden von praktischen Standorten auf der ganzen Welt verfügbar sind. Heute findest du Neosurf-Bargeldgutscheine in Kiosken, Supermärkten, Tankstellen, Zeitungsläden, Tabakläden, Internetcafés und mehr.</p>
        <button className="button big" onClick={openFindNeosurf}>Where to find Neosurf?</button>
      </div>
    </ContentRow>
    <ContentRow backgroundColor="#eee">
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Online bezahlen & spielen mit Neosurf</h2>
            <p className="textColorDark">Möchtest du keine Debit-/Kreditkarten für Online-Zahlungen verwenden? Oder hast du vielleicht gar keine? Möchtest du keine sensiblen Bankdaten angeben, weil Datenschutz für dich oberste Priorität hat?</p>
            <p>Neosurf könnte die perfekte Lösung für dich sein! Kaufe einfach einen Neosurf-Gutschein in bar und bezahle & spiele online sicher. So einfach ist das! Sieh dir ein kurzes Video an, das erklärt, wie das Neosurf-Gutscheinsystem funktioniert.</p>
          </div>
        </div>
        <div>
          <div className="videoWrapper">
            <iframe
              title="neosurfVideo"
              src="https://www.youtube.com/embed/ZZKglPJVhFE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">Bereits einen Gutschein gekauft?<br />Gehe zurück zur Kasse und zahle ein!</h2>
      <div className="grid c-4 bigGap">
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5">
              <Img fluid={data.lilly.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">
              <Img fluid={data.sevenSB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.777bay.com/?a_aid=5a6fb263dabe6">
              <Img fluid={data.sevenB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PaysafeCardPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    paysafeCard: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "paysafecard/mac1.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    neosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "paysafecard/neosurf2_2.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    findNeosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "bg2.jpg"}) {
      childImageSharp {
        fixed(width: 524) {
          aspectRatio
          src
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lucky-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/betbtc-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lilly: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lilly-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/emucasino-logo-bw.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777bay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777slotsbay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
